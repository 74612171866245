import allConstantsMixin from "@/mixins/allConstants";

import claimsService from "@/services/claims";
import accountsService from "@/services/accounts";

export default {
  mixins: [allConstantsMixin],
  computed: {
    currentClaimSelected() {
      return this.$store.getters["currentClaimSelected/currentClaim"];
    },
  },
  methods: {
    async loadClaim(claimId) {
      const getResults = await Promise.allSettled([
        claimsService.getById(claimId),
        claimsService.getCurrentWfStatus(claimId),
        //accountsService.getByIdentification(userIdentification),
      ]);

      let realClaim = getResults[0].value.data;
      realClaim.currentEvent =
        getResults[1].status == "fulfilled"
          ? getResults[1].value.data.currentEvent
          : null;
      //realClaim.account = getResults[2].value.data.account;

      //get the last event in wich a user has participated
      const humanEvents = realClaim.claim.events.filter(
        (event) =>
          event.stageCode != "INGRESO" &&
          event.userId != "" &&
          event.userId != "00000000-0000-0000-0000-000000000000" &&
          event.rol != this.allConstants.security.userRoles.claimant
      );

      if (humanEvents.length == 0) {
        realClaim.lastHumanEvent = null;
      } else {
        let lastHumanEvent = humanEvents[humanEvents.length - 1];

        const getByIdResult = await accountsService.getById(
          lastHumanEvent.userId
        );
        const lastHumanEventAccount = getByIdResult.data.account;

        lastHumanEvent.userName = `${lastHumanEventAccount.name} ${lastHumanEventAccount.surname}`;
        realClaim.lastHumanEvent = lastHumanEvent;
      }

      return this.$store.dispatch(
        "currentClaimSelected/createOrUpdate",
        realClaim
      );
    },
    reloadClaim() {
      if (this.currentClaimSelected?.currentFilter) {
        const currentFilterWfStatus = this.currentClaimSelected.currentFilter
          .requiredWfStatus;
        const currentFilterClaimStatus = this.currentClaimSelected.currentFilter
          .requiredMacroStatus;

        return this.loadClaim(
          this.currentClaimSelected.claim.id,
          this.currentClaimSelected.account.identificationNumber,
          currentFilterWfStatus,
          currentFilterClaimStatus
        );
      }
      return this.loadClaim(
        this.currentClaimSelected.claim.id,
        this.currentClaimSelected.account.identificationNumber
      );
    },
    async loadClaimForWf(claimId, userIdentification, fromRouteName) {
      const getResults = await Promise.allSettled([
        claimsService.getById(claimId),
        claimsService.getCurrentWfStatus(claimId),
        //accountsService.getByIdentification(userIdentification),
      ]);

      let realClaim = getResults[0].value.data;
      realClaim.currentEvent =
        getResults[1].status == "fulfilled"
          ? getResults[1].value.data.currentEvent
          : null;
      //realClaim.account = getResults[0].value.data.account;
      realClaim.fromRouteName = fromRouteName;

      //get the last event in wich a user has participated
      const humanEvents = realClaim.claim.events.filter(
        (event) =>
          event.stageCode != "INGRESO" &&
          event.userId != "" &&
          event.userId != "00000000-0000-0000-0000-000000000000" &&
          event.rol != this.allConstants.security.userRoles.claimant
      );

      if (humanEvents.length == 0) {
        realClaim.lastHumanEvent = null;
      } else {
        let lastHumanEvent = humanEvents[humanEvents.length - 1];

        const getByIdResult = await accountsService.getById(
          lastHumanEvent.userId
        );
        const lastHumanEventAccount = getByIdResult.data.account;

        lastHumanEvent.userName = `${lastHumanEventAccount.name} ${lastHumanEventAccount.surname}`;
        realClaim.lastHumanEvent = lastHumanEvent;
      }

      //persist selected claim
      return this.$store.dispatch(
        "currentClaimSelected/createOrUpdate",
        realClaim
      );
    },
    reloadClaimForWf() {
      this.loadClaimForWf(
        this.currentClaimSelected.claim.id,
        this.currentClaimSelected.account.identificationNumber,
        this.currentClaimSelected.fromRouteName
      );
    },
    unloadClaim() {
      return this.$store.dispatch("currentClaimSelected/clear");
    },
    async loadClaimHistory() {
      const participants = [];
      const eventsHistory = [];

      participants.push({
        id: "00000000-0000-0000-0000-000000000000",
        name: "Sistema",
        roleId: this.allConstants.security.userRolesCodes.engine,
        roleName: "Sistema",
      });

      for (let event of this.currentClaimSelected.claim.events) {
        if (event.rol != this.allConstants.security.userRoles.engine) {
          const participant = participants.find(
            (participant) => participant.id == event.userId
          );
          if (!participant) {
            const getByIdResult = await accountsService.getById(event.userId);
            let roleName;

            if (
              getByIdResult.data.account.roleId ==
              this.allConstants.security.userRolesCodes.claimant
            )
              roleName = "Cliente";

            if (
              getByIdResult.data.account.roleId ==
              this.allConstants.security.userRolesCodes.ddcExecutive
            )
              roleName = "Ejecutivo DDC";

            if (
              getByIdResult.data.account.roleId ==
              this.allConstants.security.userRolesCodes.ddcDefender
            )
              roleName = "Defensor DDC";

            if (
              getByIdResult.data.account.roleId ==
              this.allConstants.security.userRolesCodes.bankAdministrator
            )
              roleName = "Administrador banco";

            if (
              getByIdResult.data.account.roleId ==
              this.allConstants.security.userRolesCodes.bankExecutive
            )
              roleName = "Ejecutivo banco";

            if (
              getByIdResult.data.account.roleId ==
              this.allConstants.security.userRolesCodes.wfAdministrator
            )
              roleName = "Administrador";

            if (
              getByIdResult.data.account.roleId ==
              this.allConstants.security.userRolesCodes.engine
            )
              roleName = "Motor";

            participants.push({
              id: getByIdResult.data.account.id,
              name: `${getByIdResult.data.account.name} ${getByIdResult.data.account.surname}`,
              roleId: getByIdResult.data.account.roleId,
              roleName: roleName,
            });
          }
        }
      }

      for (let event of this.currentClaimSelected.claim.events) {
        const participant = participants.find(
          (participant) => participant.id == event.userId
        );

        eventsHistory.push({
          id: event.id,
          actionCode: event.actionCode,
          stageCode: event.stageCode,
          statusCode: event.stateCode,
          participant: participant,
          comments: event.comments,
          reasonCode: event.reasonCode,
          documentIds: event.documentIds,
          creationDate: event.creationDate,
        });
      }

      return eventsHistory;
    },
    checkIfActionCanBeExecuted(actionCode, posibleValues) {
      const result = {
        canBe: false,
        errorCode: "",
        errorMessage: "",
      };

      const totalDocs = this.currentClaimSelected.claim.documents.filter(
        (document) => !document.virtual
      ).length;
      const approvedDocInFirstInstance = this.currentClaimSelected.claim.documents.filter(
        (document) =>
          !document.virtual &&
          document.state ==
            this.allConstants.claims.documentStatuses.approvedFirstInstance
      ).length;
      const rejectedDocInFirstInstance = this.currentClaimSelected.claim.documents.filter(
        (document) =>
          !document.virtual &&
          document.state ==
            this.allConstants.claims.documentStatuses.rejectedFirstInstance
      ).length;

      //Acciones del ejecutivo DDC
      if (actionCode == this.allConstants.claims.posibleActions.admitDDC) {
        if (approvedDocInFirstInstance < totalDocs) {
          result.errorMessage =
            "No puede ejecutar esta acción porque no todos los documentos están aprobados";
        } else {
          result.canBe = true;
        }
      }

      if (actionCode == this.allConstants.claims.posibleActions.presentToBank) {
        if (approvedDocInFirstInstance < totalDocs) {
          result.errorMessage =
            "No puede ejecutar esta acción porque no todos los documentos están aprobados";
        } else {
          result.canBe = true;
        }
      }

      if (actionCode == this.allConstants.claims.posibleActions.rejectByForm) {
        if (rejectedDocInFirstInstance == 0) {
          result.errorMessage =
            "No puede ejecutar esta acción porque ningun documento ha sido rechazado";
        } else {
          result.canBe = true;
        }
      }

      if (
        actionCode == this.allConstants.claims.posibleActions.rejectByDeep ||
        actionCode == this.allConstants.claims.posibleActions.assignDefender ||
        actionCode == this.allConstants.claims.posibleActions.reAssignDefender ||
        actionCode == this.allConstants.claims.posibleActions.rejectCompliance ||
        actionCode == this.allConstants.claims.posibleActions.closeClaim ||
        actionCode == this.allConstants.claims.posibleActions.silentClaim
      ) {
        result.canBe = true;
      }

      //Acciones del reclamante
      if (actionCode == this.allConstants.claims.posibleActions.regularize) {
        if (rejectedDocInFirstInstance > 0) {
          result.canBe = false;
        } else {
          result.canBe = true;
        }
      }

      //Acciones del administrador banco
      if (
        actionCode ==
          this.allConstants.claims.posibleActions.acceptFastSolution ||
        actionCode ==
          this.allConstants.claims.posibleActions.rejectFastSolution ||
        actionCode == this.allConstants.claims.posibleActions.sendCompliance
      ) {
        result.canBe = true;
      }

      if (actionCode == this.allConstants.claims.posibleActions.assignBankExecutive
      ) {
        result.canBe = true;
      }

      //Acciones del defensor DDC
      if (actionCode == this.allConstants.claims.posibleActions.acceptAndPresentToBank ||
          actionCode == this.allConstants.claims.posibleActions.rejectByNotCompatible ||
          actionCode == this.allConstants.claims.posibleActions.acceptPapers ||
          actionCode == this.allConstants.claims.posibleActions.decisionInFavorOfBank ||
          actionCode == this.allConstants.claims.posibleActions.decisionInFavorOfClient ||
          actionCode == this.allConstants.claims.posibleActions.decisionInCompromise ||
          actionCode == this.allConstants.claims.posibleActions.sendPapersClient ||
          actionCode == this.allConstants.claims.posibleActions.rejectByDeepDefender ||
          actionCode == this.allConstants.claims.posibleActions.rejectExtension ||
          actionCode == this.allConstants.claims.posibleActions.sendPapersBank ||
          actionCode == this.allConstants.claims.posibleActions.sendPapersClient ||
          actionCode == this.allConstants.claims.posibleActions.acceptCompromiseDefender
      ) {
        result.canBe = true;
      }

      if (
        actionCode == this.allConstants.claims.posibleActions.sendPapersParallel
      ) {
        if (
          this.currentClaimSelected.claim.lastActionCode ==
            this.allConstants.claims.posibleActions
              .resultSendParallelPapersBank ||
          this.currentClaimSelected.claim.lastActionCode ==
            this.allConstants.claims.posibleActions
              .resultSendParallelPapersClient ||
          this.currentClaimSelected.claim.lastActionCode ==
            this.allConstants.claims.posibleActions.resultSendPapersBank ||
          this.currentClaimSelected.claim.lastActionCode ==
            this.allConstants.claims.posibleActions.resultSendPapersClient
        ) {
          result.canBe = false;
          result.errorMessage =
            "No puede ejecutar esta acción porque ya se solicitaron documentos adicionales cliente y banco";
        } else {
          const totalEventoBank = this.currentClaimSelected.claim.events.filter(
            (event) =>
              event.actionCode ==
              this.allConstants.claims.posibleActions
                .resultSendParallelPapersBank
          ).length;
          const totalEventoClient = this.currentClaimSelected.claim.events.filter(
            (event) =>
              event.actionCode ==
              this.allConstants.claims.posibleActions
                .resultSendParallelPapersClient
          ).length;
          if (totalEventoBank + totalEventoClient > 0) {
            result.canBe = false;
            result.errorMessage =
              "No puede ejecutar esta acción porque ya se solicitaron documentos adicionales cliente y banco";
          } else {
            result.canBe = true;
          }
        }
      }

      /*if (actionCode == this.allConstants.claims.posibleActions.sendPapersBank) {
                if (this.currentClaimSelected.claim.lastActionCode == this.allConstants.claims.posibleActions.resultSendPapersBank ||
                    this.currentClaimSelected.claim.lastActionCode == this.allConstants.claims.posibleActions.resultSendParallelPapersBank ||
                    this.currentClaimSelected.claim.lastActionCode == this.allConstants.claims.posibleActions.resultSendParallelPapersClient) {
                    result.canBe = false;
                    result.errorMessage = "No puede ejecutar esta acción porque ya se solicitaron documentos adicionales al banco";
                } else {
                    const totalEvento = this.currentClaimSelected.claim.events.filter(event => event.actionCode == this.allConstants.claims.posibleActions.resultSendPapersBank).length;
                    if (totalEvento > 0) {
                        result.canBe = false;
                        result.errorMessage = "No puede ejecutar esta acción porque ya se solicitaron documentos adicionales al banco";
                    }
                    else {
                        result.canBe = true;
                    }
                }
            }

            if (actionCode == this.allConstants.claims.posibleActions.sendPapersClient) {
                if (this.currentClaimSelected.claim.lastActionCode == this.allConstants.claims.posibleActions.resultSendPapersClient ||
                    this.currentClaimSelected.claim.lastActionCode == this.allConstants.claims.posibleActions.resultSendParallelPapersBank ||
                    this.currentClaimSelected.claim.lastActionCode == this.allConstants.claims.posibleActions.resultSendParallelPapersClient) {
                    result.canBe = false;
                    result.errorMessage = "No puede ejecutar esta acción porque ya se solicitaron documentos adicionales al cliente";
                } else {
                    const totalEvento = this.currentClaimSelected.claim.events.filter(event => event.actionCode == this.allConstants.claims.posibleActions.resultSendPapersClient).length;
                    if (totalEvento > 0) {
                        result.canBe = false;
                        result.errorMessage = "No puede ejecutar esta acción porque ya se solicitaron documentos adicionales al cliente";
                    }
                    else {
                        result.canBe = true;
                    }
                }
            }*/

      if (
        actionCode == this.allConstants.claims.posibleActions.acceptExtension
      ) {
        const objValue = this.currentClaimSelected.claim.values.find(
          (v) => v.name == "días de prórroga solicitados"
        );
        const hourExtensionDefender = parseInt(posibleValues[0].input);
        const hourExtensionExecutive = parseInt(objValue.value);

        if (hourExtensionDefender > hourExtensionExecutive) {
          result.canBe = false;
          result.errorMessage =
            "La cantidad de días aprobados no puede ser mayor al solicitado.";
        } else {
          result.canBe = true;
        }
      }

      //Acciones del ejecutivo banco
      if (
        actionCode == this.allConstants.claims.posibleActions.sendPapers ||
        actionCode ==
          this.allConstants.claims.posibleActions.resultSendPapersBank ||
        actionCode ==
          this.allConstants.claims.posibleActions.resultSendParallelPapersBank
      ) {
        result.canBe = true;
      }

      if (
        actionCode ==
        this.allConstants.claims.posibleActions.requestTimeExtension
      ) {
        const hourExtensionExecutor = parseInt(posibleValues[0].input);

        if (hourExtensionExecutor > 15) {
          result.canBe = false;
          result.errorMessage =
            "La cantidad de días solicitados no puede ser mayor a 15.";
        } else {
          result.canBe = true;
        }
      }

      return result;
    },
    changeWfStatusFromAction(
      actionCode,
      reasonCode,
      comments,
      documents,
      ddcAssignedUserId,
      bankAssignedUserId,
      posibleValues
    ) {
      const claimId = this.currentClaimSelected.claim.id;
      const currentWfStatus = this.currentClaimSelected.currentEvent.stateCode;

      const actionContent = {
        reasonCode: reasonCode ?? "",
        comments: comments ?? "",
        documents: this.generateVirtualDocuments(
          actionCode,
          reasonCode,
          documents
        ), //Solo se generan documentos virtuales si es necesario. En caso contrario son los de la etapa unicamente
        values: this.generateValues(posibleValues),
        UserIdDDC: ddcAssignedUserId,
        UserIdBank: bankAssignedUserId,
      };

      return claimsService.changeWfStatusFromAction(
        claimId,
        currentWfStatus,
        actionCode,
        actionContent
      );
    },
    forceChangeWfStatusFromAction(
      currentWfStatus,
      actionCode,
      reasonCode,
      comments,
      documents
    ) {
      const claimId = this.currentClaimSelected.claim.id;
      const actionContent = {
        reasonCode: reasonCode ?? "",
        comments: comments ?? "",
        documents: documents ? documents : [],
      };

      return claimsService.changeWfStatusFromAction(
        claimId,
        currentWfStatus,
        actionCode,
        actionContent
      );
    },
    generateValues(posibleValues) {
      let result = [];

      if (posibleValues != undefined && posibleValues.length > 0) {
        posibleValues.forEach((v) => {
          result.push({
            name: v.name,
            value: v.input,
          });
        });
      }

      return result;
    },
    generateVirtualDocuments(actionCode, reasonCode, currentActionDocuments) {
      const actionDocumentList = currentActionDocuments
        ? currentActionDocuments
        : [];

      //Solo se generan documentos virtuales si es necesario

      if (actionCode == this.allConstants.claims.posibleActions.rejectByForm) {
        //caso de rechazo por forma
        const rejectedDocuments = this.currentClaimSelected.claim.documents
          .filter(
            (document) =>
              document.state ==
              this.allConstants.claims.documentStatuses.rejectedFirstInstance
          )
          .map((document) => {
            return { name: document.name };
          });

        actionDocumentList.push({
          blobId: this.$uuid.v4(),
          type: "SYSTEM",
          virtual: "true",
          name: "Carta de rechazo por forma",
          fileName: "Carta de rechazo por forma",
          contentType: "none",
          contentLenght: "0",
          metadata: {
            relatedAction: actionCode,
            relatedReasonCode: reasonCode,
            relatedDocuments: rejectedDocuments,
          },
        });
      }

      if (
        actionCode == this.allConstants.claims.posibleActions.rejectByDeep ||
        actionCode ==
          this.allConstants.claims.posibleActions.rejectByDeepDefender
      ) {
        //caso de rechazo por fondo
        actionDocumentList.push({
          blobId: this.$uuid.v4(),
          type: "SYSTEM",
          virtual: "true",
          name: "Carta de rechazo por fondo",
          fileName: "Carta de rechazo por fondo",
          contentType: "none",
          contentLenght: "0",
          metadata: {
            relatedAction: actionCode,
            relatedReasonCode: reasonCode,
            relatedDocuments: [],
          },
        });
      }

      //Se devuelven los documentos originales de la etapa más los virtuales que se generaron
      return actionDocumentList;
    },
    getEvent(forActionCode) {
      return this.currentClaimSelected.claim.events.find(
        (event) => event.actionCode == forActionCode
      );
    },
    async getEventAndUser(forActionCode) {
      let resolutionEvent = this.currentClaimSelected.claim.events.find(
        (event) => event.actionCode == forActionCode
      );
      if (!resolutionEvent) return;

      const userAccountGetByIdResult = await accountsService.getById(
        resolutionEvent.userId
      );

      resolutionEvent.userName = `${userAccountGetByIdResult.data.account.name} ${userAccountGetByIdResult.data.account.surname}`;

      return resolutionEvent;
    },
    async regularizeIfIsPossible() {
      const resultCheck = this.checkIfActionCanBeExecuted(
        this.allConstants.claims.posibleActions.regularize
      );
      if (!resultCheck.canBe) {
        return;
      }

      await this.forceChangeWfStatusFromAction(
        "RECHAZADO-FORMA",
        this.allConstants.claims.posibleActions.regularize
      );
      return this.reloadClaim();
    },
  },
};
