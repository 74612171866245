<template>
  <div>
    <v-form v-model="form.isValid" @submit.prevent="search">
      <v-row>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="folioSearch"
            label="N° Reclamo"
            placeholder="Ingrese un N° Reclamo"
            outlined
            dense
            required
            :rules="folioRules"
            :disabled="form.processing || loading"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="rutSearch"
            label="Rut"
            placeholder="Ingrese rut del reclamante"
            outlined
            dense
            required
            :rules="rutRules"
            :disabled="form.processing || loading"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn
            class="mx-2"
            fab
            small
            dark
            color="blue lighten-3"
            :disabled="!form.isValid || form.processing"
            :loading="loading"
            @click="eraser"
          >
            <v-icon>
              mdi-eraser
            </v-icon>
          </v-btn>
          <v-btn
            type="submit"
            class="mx-2"
            fab
            small
            color="primary"
            :disabled="!form.isValid || form.processing"
            :loading="loading"
          >
            <v-icon dark>
              mdi-magnify
            </v-icon>
          </v-btn>
          <ImportExcel
            v-if="importExcel"
            :disabled="!form.isValid || form.processing"
            :loading="loading"
            :data="dataExportExcel"
          />
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="text-right"
          v-if="claims.length > 0 && !loading"
        >
          <v-spacer /> Cantidad de reclamos {{ $n(currentPage.totalRecords) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="2" v-if="showSearchBank">
          <v-select
            label="Banco"
            outlined
            dense
            v-model="bancoSearch"
            :disabled="form.processing || loading"
            :items="
              banks.map((bank) => {
                return { value: bank.code, text: bank.name };
              })
            "
            clearable
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="2" v-if="showSearchState">
          <v-select
            label="Estado"
            outlined
            dense
            v-model="estadoSearch"
            :disabled="form.processing || loading"
            :items="computedState"
            clearable
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="2" v-if="showSearchDefender">
          <v-select
            label="Defensores"
            outlined
            dense
            v-model="defensorSearch"
            :disabled="form.processing || loading"
            :items="
              defenders.map((defender) => {
                return { value: defender.id, text: defender.name };
              })
            "
            clearable
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="2" v-if="showSearchTypolies">
          <v-select
            label="Tipología de Reclamo"
            outlined
            dense
            v-model="typolieSearch"
            :disabled="form.processing || loading"
            :items="typolies"
            clearable
          >
          </v-select>
        </v-col>
        <v-col cols="12" md="3" v-if="showSearchDate">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Fecha Creación"
                outlined
                dense
                readonly
                :disabled="form.processing || loading"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              scrollable
              color="primary"
              range
              locale="es-CL"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.dialog.save(dates)">
                OK
              </v-btn>
              <v-btn text color="primary" @click="modal = false">
                Cerrar
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="computedHeaders"
          :items="claims"
          :loading="loading"
          loading-text="Cargando reclamos"
          :no-data-text="noDataText"
          :items-per-page="itemsPerPage"
          hide-default-footer
          dense
          @update:options="custom"
          :sort-by="computedSortKey"
          :sort-desc="orderType"
        >
          <template v-slot:[`item.internalFolio`]="{ item }">
            <v-progress-linear
              buffer-value="0"
              stream
              color="cyan"
              v-if="form.processing || loading"
            ></v-progress-linear>
            <router-link
              to="#"
              v-on:click.native="gotoClaim(item.claimId, item.rut)"
              v-else
              >{{ item.internalFolio }}</router-link
            >
          </template>
          <template v-slot:[`item.rut`]="{ item }">
            <div class="one-line">{{ item.rut | formatRut }}</div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div v-if="item.name.length <= 25">{{ item.name }}</div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="one-line" v-bind="attrs" v-on="on"
                    >{{ item.name.substring(0, 25) }}...</span
                  >
                </template>
                <span>{{ item.name }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.userIdDDC`]="{ item }">
            <div v-if="getNameDefender(item.userIdDDC).length <= 17">
              {{ getNameDefender(item.userIdDDC) }}
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="one-line" v-bind="attrs" v-on="on"
                    >{{
                      getNameDefender(item.userIdDDC).substring(0, 17)
                    }}...</span
                  >
                </template>
                <span>{{ getNameDefender(item.userIdDDC) }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.bankCode`]="{ item }">
            {{ getBankName(item.bankCode) }}
          </template>
          <template v-slot:[`item.lastActionCode`]="{ item }">
            {{ item.lastActionCode | wfStatusFromAction }}
          </template>
          <template v-slot:[`item.creationDate`]="{ item }">
            <span class="one-line" v-if="item.creationDate">{{
              item.creationDate | formatDateShort
            }}</span>
            <span class="one-line" v-else>{{
              item.startDate | formatDateShort
            }}</span>
          </template>
          <template v-slot:[`item.plazo`]="{ item }" v-if="showDaysLeft">
            <div class="one-line" v-if="item.howMuchLeft != undefined">
              <v-icon v-if="item.flag == 'Red'" color="red"
                >mdi-clock-time-four-outline</v-icon
              >
              <v-icon v-if="item.flag == 'Green'" color="green"
                >mdi-clock-time-four-outline</v-icon
              >
              <v-icon v-if="item.flag == 'Yellow'" color="yellow"
                >mdi-clock-time-four-outline</v-icon
              >
              {{ item.howMuchLeft.value | formatDaysFromHours }}
            </div>
          </template>
          <template v-slot:[`item.typology`]="{ item }" v-if="showTipology">
            <div v-if="item.typology != null">
              <div v-if="getNameTypology(item.typology).length <= 25">
                {{ getNameTypology(item.typology) }}
              </div>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <span class="one-line" v-bind="attrs" v-on="on"
                    >{{
                      getNameTypology(item.typology).substring(0, 25)
                    }}...</span
                  >
                </template>
                <span>{{ getNameTypology(item.typology) }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>

        <v-divider></v-divider>
        <v-pagination
          :disabled="loading || form.processing"
          v-model="currentPage.pageNumber"
          :length="currentPage.totalPages"
          :total-visible="10"
          @input="changePage"
          v-if="claims.length > 0 && currentPage.totalPages > 1 && !loading"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import wfStatusFiltersMixin from "@/filters/claimWfStatusFilters";

import ImportExcel from "@/components/ImportExcel";

import sxRut from "sx-rut";

import authenticationConstants from "@/plugins/authenticationConstants";

export default {
  mixins: [baseViewMixin, parametersMixin, currentUserMixin],
  components: {
    ImportExcel,
  },
  props: {
    claims: {
      type: Array,
    },
    showDaysLeft: {
      type: Boolean,
      default: true,
    },
    showTipology: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: "No hay reclamos",
    },
    showCustomSort: {
      type: Boolean,
      default: false,
    },
    showSearchBank: {
      type: Boolean,
      default: false,
    },
    showSearchState: {
      type: Boolean,
      default: false,
    },
    showSearchDate: {
      type: Boolean,
      default: false,
    },
    showSearchDefender: {
      type: Boolean,
      default: false,
    },
    showNameDefender: {
      type: Boolean,
      default: false,
    },
    showSearchTypolies: {
      type: Boolean,
      default: false,
    },
    sortKey: {
      type: String,
      default: "",
    },
    orderType: {
      type: Boolean,
      default: false,
    },
    importExcel: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    changePageFilter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedState() {
      let result = this.states.map((state) => {
        return { value: state.code, text: state.name };
      });

      const indexOne = result.findIndex(
        (key) => key.value === "PENDIENTE-ADMISION"
      );
      result.splice(indexOne, 1);

      const indexTwo = result.findIndex(
        (key) => key.value === "ANTECEDENTES-ADICIONALES-PARALELO-BANCO"
      );
      result.splice(indexTwo, 1);

      const indexThree = result.findIndex(
        (key) => key.value === "ANTECEDENTES-ADICIONALES-PARALELO-CLIENTE"
      );
      result.splice(indexThree, 1);

      const indexFour = result.findIndex(
        (key) => key.value === "CREACION-RECLAMO"
      );
      result.splice(indexFour, 1);

      return result;
    },
    computedHeaders() {
      if (!this.currentUserIsAuthenticated) return this.headersHideDaysLeft;

      const userRole = this.currentUserInfo.roles[0];
      if (userRole != authenticationConstants.userRolesCodes.ddcExecutive) {
        if (this.showDaysLeft)
          return this.headers.filter((h) => h.value != "previousClaims");
        if (this.showTipology)
          return this.headersTypology.filter(
            (h) => h.value != "previousClaims"
          );
        if (this.showNameDefender)
          return this.headersNameDefender.filter(
            (h) => h.value != "previousClaims"
          );

        return this.headersHideDaysLeft.filter(
          (h) => h.value != "previousClaims"
        );
      } else {
        if (this.showDaysLeft) return this.headers;
        if (this.showTipology) return this.headersTypology;
        if (this.showNameDefender) return this.headersNameDefender;
      }

      return this.headersHideDaysLeft;
    },
    computedSortKey() {
      switch (this.sortKey) {
        case "f":
          return "internalFolio";
        case "r":
          return "rut";
        case "bc":
          return "bankCode";
        case "cd":
          return "creationDate";
        default:
          return "";
      }
    },
    computedFilter() {
      let date = {
        key: "cd",
        value: "",
        from: "",
        to: "",
      };

      if (this.dates.length == 1) date.value = this.dates[0];

      if (this.dates.length == 2) {
        date.from = this.dates[0];
        date.to = this.dates[1];
      }

      return [
        { key: "lsc", value: this.estadoSearch },
        { key: "if", value: this.folioSearch },
        { key: "r", value: this.rutSearch?.toUpperCase() },
        { key: "bc", value: this.bancoSearch },
        { key: "d", value: this.defensorSearch },
        { key: "tp", value: this.typolieSearch },
        date,
      ];
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    dataExportExcel() {
      let result = [];
      this.claims.forEach((c) => {
        c.bankName = this.getBankName(c.bankCode);
        c.defenderName = this.getNameDefender(c.userIdDDC);
        c.TypologyName = this.getNameTypology(c.typology);

        c.state = wfStatusFiltersMixin.wfStatusFromAction(c.lastActionCode);
        if (c.lastUpdateDate == "0001-01-01T00:00:00Z") c.lastUpdateDate = "";

        //arreglar fechas

        result.push(c);
      });
      return result;
    },
  },
  data() {
    return {
      folioSearch: "",
      rutSearch: "",
      bancoSearch: "",
      defensorSearch: "",
      typolieSearch: "",
      banks: [],
      dates: [],
      typolies: [],
      estadoSearch: "",
      states: [],
      modal: false,
      headers: [
        { text: "N° Reclamo", value: "internalFolio", search: true },
        { text: "Rut", value: "rut", search: true },
        { text: "Cliente", value: "name", search: true },
        { text: "Reclamos Previos", value: "previousClaims", search: false },
        { text: "Banco", value: "bankCode" },
        { text: "Estado", value: "lastActionCode" },
        { text: "Fecha Creación", value: "creationDate" },
        { text: "Plazo", value: "plazo", sortable: false },
      ],
      headersHideDaysLeft: [
        { text: "N° Reclamo", value: "internalFolio", search: true },
        { text: "Rut", value: "rut", search: true },
        { text: "Cliente", value: "name", search: true },
        { text: "Reclamos Previos", value: "previousClaims", search: false },
        { text: "Banco", value: "bankCode" },
        { text: "Estado", value: "lastActionCode" },
        { text: "Fecha Creación", value: "creationDate" },
      ],
      headersNameDefender: [
        { text: "N° Reclamo", value: "internalFolio", search: true },
        { text: "Rut", value: "rut", search: true },
        { text: "Cliente", value: "name", search: true },
        { text: "Reclamos Previos", value: "previousClaims", search: false },
        { text: "Defensor", value: "userIdDDC", search: true },
        { text: "Banco", value: "bankCode" },
        { text: "Estado", value: "lastActionCode" },
        { text: "Fecha Creación", value: "creationDate" },
      ],
      headersTypology: [
        { text: "N° Reclamo", value: "internalFolio", search: true },
        { text: "Rut", value: "rut", search: true },
        { text: "Cliente", value: "name", search: true },
        { text: "Reclamos Previos", value: "previousClaims", search: false },
        { text: "Defensor", value: "userIdDDC", search: true },
        { text: "Banco", value: "bankCode" },
        { text: "Estado", value: "lastActionCode" },
        { text: "Fecha Creación", value: "creationDate" },
        { text: "Tipología", value: "typology" },
      ],
      sort: {
        key: "f",
        orderType: 1,
      },
      rutRules: [
        (value) =>
          !value ||
          this.allConstants.regex.regexRut.test(value) ||
          "No es válido",
        (value) => !value || sxRut.isValidRut(value) || "ingrese un rut válido",
      ],
      folioRules: [
        (value) =>
          !value ||
          this.allConstants.regex.regexDescription.test(value) ||
          "No es válido",
      ],
    };
  },
  methods: {
    custom(options) {
      if (!this.showCustomSort) return;
      if (options.sortBy.length == 0 && options.sortDesc.length == 0) return;

      return;
      /*
      const name = options.sortBy[0];
      const isDesc = options.sortDesc[0];

      switch (name) {
        case "internalFolio":
          this.sort.key = "f";
          break;
        case "rut":
          this.sort.key = "r";
          break;
        case "bankCode":
          this.sort.key = "bc";
          break;
        case "creationDate":
          this.sort.key = "cd";
          break;
        default:
          return;
      }

      this.sort.orderType = isDesc ? 1 : 0;
      this.changePage(this.currentPage.pageNumber);
      */
    },
    search() {
      this.$emit("search", this.computedFilter);
    },
    gotoClaim(claimId, userIdentification) {
      this.initSignalProcessing();
      this.$emit("gotoClaim", {
        claimId: claimId,
        userIdentification: userIdentification,
      });
    },
    eraser() {
      this.estadoSearch = "";
      this.folioSearch = "";
      this.rutSearch = "";
      this.bancoSearch = "";
      this.defensorSearch = "";
      this.typolieSearch = "";
      this.dates = [];
    },
    changePage(toPageNumber) {
      if (this.changePageFilter)
        this.$emit("changePage", {
          toPageNumber: toPageNumber,
          filter: this.computedFilter,
          sort: this.sort,
        });
      else this.$emit("changePage", toPageNumber);
    },
    getNameTypology(value) {
      const typology = this.typolies.find(
        (typoligy) => typoligy.value == value
      );
      if (typology == undefined || typology == null) return "";
      else return typology.text;
    },
  },
  beforeMount() {
    const parametersBank = this.$Parameters.all.find(
      (parameter) => parameter.type == "MigrationBanks"
    );
    this.banks = parametersBank.values;

    const parametersStates = this.$Parameters.all.find(
      (parameter) => parameter.type == "WfStatuses"
    );
    this.states = parametersStates.values;

    if (this.showSearchDefender) {
      this.loadDefenders();
    }

    if (this.showSearchTypolies) {
      const typology = this.$Parameters.all.find(
        (parameter) => parameter.type == "Typology"
      ).values;
      typology.forEach((t) => {
        this.typolies.push({ value: t.code, text: t.name });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
</style>
