<template>
  <v-card elevation="1" tile class="pa-5">
    <div class="subtitle-1 font-weight-bold mb-5">RECLAMOS CERRADOS</div>

    <DataTables
      :claims="claims"
      :showDaysLeft="false"
      :showCustomSort="true"
      :loading="page.loading"
      :changePageFilter="true"
      @gotoClaim="gotoClaim"
      @changePage="changePage"
      @search="search"
      :sortKey="sortKey"
      :orderType="orderType"
    />
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";
import currentDashboardMixin from "@/mixins/currentDashboard";

import claimsService from "@/services/claims";
import DataTables from "@/components/WFSite/Claims/DataTables";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
    currentDashboardMixin,
  ],
  components: { DataTables },
  data() {
    return {
      claims: [],
      sortKey: "",
      orderType: false,
    };
  },
  methods: {
    async gotoClaim(selectedClaimInfo) {
      await this.loadClaimForWf(
        selectedClaimInfo.claimId,
        selectedClaimInfo.userIdentification,
        this.$route.name
      );
      this.gotoView("claimDetailInWf");
    },
    getClaims(requiredPageNumber, filter, sort) {
      this.initSignalLoading();

      let pageNumber = requiredPageNumber ? requiredPageNumber : 1;
      if (pageNumber > 1) this.loadDashboard();

      if (sort == null || sort == undefined) {
        sort = {
          key: "f",
          orderType: 1,
        };
      } else {
        this.sortKey = sort.key;
        this.orderType = sort.orderType == 1 ? true : false;
      }

      const currentPageData = this.currentPage;

      claimsService
        .getByMacroStatus(
          this.allConstants.dashboard.groups.ddcExecutive.claimsClosed.statuses,
          pageNumber,
          15,
          filter,
          sort
        )
        .then((getResult) => {
          this.claims = getResult.data.claims;
          currentPageData.totalPages = getResult.data.pagesTotal;
          currentPageData.totalRecords = getResult.data.recordsTotal;

          currentPageData.routerName = this.$route.name;
          currentPageData.pageNumber = pageNumber;

          this.createOrUpdateCurrentPage(currentPageData);
          this.stopSignalLoading();
        })
        .catch(() => {
          this.claims = [];
          currentPageData.totalPages = 0;
          currentPageData.totalRecords = 0;

          currentPageData.routerName = this.$route.name;
          currentPageData.pageNumber = pageNumber;
          this.createOrUpdateCurrentPage(currentPageData);
          this.stopSignalLoading();
        });
    },
    search(filter) {
      this.claims = [];
      this.getClaims(1, filter);
    },
    changePage(change) {
      this.claims = [];
      this.getClaims(change.toPageNumber, [], change.sort);
    },
    customSort(sort) {
      let pageNumber = 1;
      if (this.currentPage.routerName == this.$route.name)
        pageNumber = this.currentPage.pageNumber ?? 1;

      this.getClaims(pageNumber, [], sort);
    },
  },
  beforeMount() {
    let pageNumber = 1;
    if (this.currentPage.routerName == this.$route.name)
      pageNumber = this.currentPage.pageNumber ?? 1;

    this.getClaims(pageNumber, []);
  },
};
</script>

<style lang="scss" scoped></style>
